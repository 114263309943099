<template>
  <div class="login-body">
    <el-form ref="loginFormRef"
             :model="loginForm"
             :rules="loginFormRules"
             label-width="80px">
      <el-form-item label="用户名" prop="username">
        <el-input v-model="loginForm.username"
                  placeholder="用户名"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input type="password"
                  v-model="loginForm.password" placeholder="密码"></el-input>
      </el-form-item>
      <el-form-item class="login-button">
        <el-button type="primary" @click="login">登录</el-button>
        <el-button type="info" @click="resetLoginForm">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
  export default {
    data(){
      return {
        loginForm: {
          username: '',
          password: ''
        },
        loginFormRules: {
          username: [
            {required: true, message: '请填写用户名', trigger: 'blur'},
            {min: 2, max: 8, message: '长度在2-8个字符', trigger: 'blur'},
          ],
          password: [
            {required: true, message: '请填写密码', trigger: 'blur'},
            {min: 3, max: 12, message: '长度在3-12个字符', trigger: 'blur'},
          ]
        }
      }
    },
    methods: {
      login() {
        this.$refs.loginFormRef.validate((valid) => {
          if (valid) {
            let param = new URLSearchParams()
            param.append('username', this.loginForm.username)
            param.append('password', this.loginForm.password)
            this.$http.post('/user/login', param).then(resp => {
              console.log(resp)
              if(resp.data.code === 200) {

                sessionStorage.setItem('token', resp.data.data)
                this.$router.push('/')
              } else {
                this.$message.error(resp.data.msg)
              }
            }).catch(e => {

            })
          } else {
            return false;
          }
        })
      },
      resetLoginForm(){
        this.$refs.loginFormRef.resetFields()
      }
    }
  }
</script>
<style scoped>
  .login-body {
    width: 100%;
    height: 100%;
    background: url('../assets/imgs/bg2.jpg') no-repeat;
    background-size: cover;
    position: fixed;
  }
  .el-form {
    width: 450px;
    height: 220px;
    background-color: #FFFFFF;
    border-radius: 15px;
    box-shadow: 0px 0px 20px #c6c6c6;
    padding-top: 50px;
    padding-right: 25px;
    position: absolute;
    top: 50%; /* 向下移动一半 */
    left: 50%; /* 向右移动一半 */
    transform: translate(-50%, -50%);
  }
  .login-button {
    position: absolute;
    left: 15%;
    margin-top: 10px;
  }
</style>